import { FC, useEffect, useState } from "react";
import { SectionHeaderCheckbox } from "../../../base/SectionHeaderCheckbox";
import { Button } from "../../../ContactsPage/Button";
import { Calendar } from "../../../ContactsPage/Calendar/Calendar";
import { DatePicker } from "../../../base/DatePicker";
import { SectionBody } from "../../../HomePage/SectionBody";
import { SectionHeader } from "../../../HomePage/SectionHeader";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  visitsTriggerActions,
} from "../../../../backend/store/reducers/visitsReducers";
import { GENDER_OPTIONS, SCHEDULER_OPTIONS, STATUS_OPTIONS } from "../../../../constants/base";
import { useUrlQueryParams } from "../../../../hooks/useUrlQueryParams";
import { RadioButton } from "../../../base/RadioButton";
import { FiltersVisits } from "../../../../enums/filtersVisitsEnum";
import { FiltersVisitsPage } from "../../../../pages/VisitPage";
import { useTranslation } from "../../../../hooks/useTranslation";

interface IProps {
  setOpen: (value: boolean) => void;
  resFilters: () => void;
  // handleSetFilters: any;
  scheduled: any;
  setScheduled: any;
  category: any;
  setCategory: any;
  gender: any;
  setGender: any;
  dueVisitStartDate: any;
  setDueVisitStartDate: any;
  dataRangeStartDate: any;
  setDataRangeStartDate: any;
  dataRangeEndDate: any;
  setDataRangeEndDate: any;
  status: any;
  setStatus: any;
  deferredVisitStartDate: any;
  setDeferredVisitStartDate: any;
  setRender: any;
}

// export const FilterSection: FC<IProps> = ({ setOpen, resFilters, handleSetFilters, setCategory, category, scheduled, setScheduled, gender, setGender, dueVisitStartDate, setDueVisitStartDate, setDeferredVisitStartDate, deferredVisitStartDate }) => {
export const FilterSection: FC<IProps> = ({
  setOpen,
  resFilters,
  // handleSetFilters,
}) => {
  const dispatch = useDispatch();
  const { p } = useTranslation('visits');
  const { p: pGeneral } = useTranslation("general");
  const { setUrlQueryParam, getUrlQueryParam, deleteUrlQueryParam } =
    useUrlQueryParams();

  const categories = useSelector(
    (state: any) => state.householdsStore.categories?.data
  );

  const scheduledFromUrl = getUrlQueryParam(FiltersVisits.periodicity);
  const regularCategoriesFromUrl = getUrlQueryParam(
    FiltersVisits.regular_categories
  );
  const genderFromURl = getUrlQueryParam(FiltersVisits.gender);
  const statusFromUrl = getUrlQueryParam(
    FiltersVisits.status
  );
  const dataRangeStartDateFromUrl = getUrlQueryParam(
    FiltersVisits.data_range_start_date
  );
  const dataRangeEndDateFromUrl = getUrlQueryParam(
    FiltersVisits.data_range_end_date
  );

  
  const [openScheduled, setOpenScheduled] = useState(!!scheduledFromUrl);
  const [openCategories, setOpenCategories] = useState(
    !!regularCategoriesFromUrl?.length
  );
  const [openGender, setOpenGender] = useState(!!genderFromURl);

  const [openDataRange, setOpenDataRange] = useState(
    !!dataRangeStartDateFromUrl || !!dataRangeEndDateFromUrl

  );
  const [openStatus, setOpenStatus] = useState(
    !!statusFromUrl
  );

  const [scheduled, setScheduled] = useState(
    scheduledFromUrl?.split(",") || []
  );
  const [category, setCategory] = useState(
    regularCategoriesFromUrl?.split(",") || []
  );
  const [gender, setGender] = useState<string | null>(genderFromURl || null);
  const [status, setStatus] = useState<string | null>(statusFromUrl || null);

  const [dataRangeStartDate, setDataRangeStartDate] = useState(
    dataRangeStartDateFromUrl || ""
  );
  const [dataRangeEndDate, setDataRangeEndDate] = useState(
    dataRangeEndDateFromUrl || ""
  );


  const resetFilters = () => {
    deleteUrlQueryParam(FiltersVisits.periodicity);
    deleteUrlQueryParam(FiltersVisits.gender);
    deleteUrlQueryParam(FiltersVisits.regular_categories);
    deleteUrlQueryParam(FiltersVisits.status);
    deleteUrlQueryParam(FiltersVisits.data_range_start_date);
    deleteUrlQueryParam(FiltersVisits.data_range_end_date);
    setOpenScheduled(false);
    setOpenCategories(false);
    setOpenGender(false);
    setOpenDataRange(false);
    setOpenStatus(false);
    setScheduled([]);
    setCategory([]);
    setGender(null);
    setStatus(null);
    setDataRangeStartDate("");
    setDataRangeEndDate("");
    // setDueVisitStartDate("");
    // setDeferredVisitStartDate("");
    resFilters();
  };

  const perPageFromUrl = getUrlQueryParam(FiltersVisitsPage.perPage);
  const pageFromUrl = getUrlQueryParam(FiltersVisitsPage.currentPage);

  const applyFilters = () => {
    if (scheduled.length)
      setUrlQueryParam(FiltersVisits.periodicity, `${scheduled.join()}`);
    if (!scheduled.length) deleteUrlQueryParam(FiltersVisits.periodicity);
    if (category.length)
      setUrlQueryParam(
        FiltersVisits.regular_categories,
        `${category.join()}`
      );
    if (!category.length)
      deleteUrlQueryParam(FiltersVisits.regular_categories);
    if (gender) setUrlQueryParam(FiltersVisits.gender, gender);
    if (!gender) deleteUrlQueryParam(FiltersVisits.gender);
    if (status) setUrlQueryParam(FiltersVisits.status, status);
    if (!status) deleteUrlQueryParam(FiltersVisits.status);
    if (dataRangeStartDate)
      setUrlQueryParam(
        FiltersVisits.data_range_start_date,
        dataRangeStartDate
      );
    if (!dataRangeStartDate)
      deleteUrlQueryParam(FiltersVisits.data_range_start_date);

    if (dataRangeEndDate)
      setUrlQueryParam(
        FiltersVisits.data_range_end_date,
        dataRangeEndDate
      );
    if (!dataRangeEndDate)
      deleteUrlQueryParam(FiltersVisits.data_range_end_date);

    // if (dueVisitStartDate)
    //   setUrlQueryParam(
    //     FiltersVisits.due_visit_start_date,
    //     dueVisitStartDate
    //   );
    // if (!dueVisitStartDate)
    //   deleteUrlQueryParam(FiltersVisits.due_visit_start_date);

    // if (deferredVisitStartDate)
    //   setUrlQueryParam(
    //     FiltersVisits.deferred_visit_start_date,
    //     deferredVisitStartDate
    //   );
    // if (!openDeffered) {
    //   deleteUrlQueryParam(FiltersVisits.deferred_visit_start_date);
    // }
    // if (!openCurrently) {
    //   deleteUrlQueryParam(FiltersVisits.due_visit_start_date);
    // }

    // const deferred = openDeffered ? deferredVisitStartDate : "";
    // const currently = openCurrently ? dueVisitStartDate : "";


    dispatch(
      visitsTriggerActions.getAllVisitsTriggerAC({
        // @ts-ignore
        page: pageFromUrl,
        // @ts-ignore
        perPage: perPageFromUrl,
        search: {
          periodicity: scheduled || undefined,
          categories: category?.length
            ? category?.map((id) => ({ id: id }))
            : undefined,
          persons_gender: gender || null,
          status: status || null,
          data_range_start_date: dataRangeStartDate || undefined,
          data_range_end_date: dataRangeEndDate || undefined,
          // due_visit_start_date: currently || undefined,
          // deferred_visit_start_date: deferred || undefined,
        },
      })
    );
    setOpen(false);
  };

  return (
    <div className="home-page-filter-section">
      <div>
        <SectionHeader
          title={p("scheduled").toUpperCase()}
          open={openScheduled}
          onClick={() => setOpenScheduled(!openScheduled)}
        />
        {openScheduled && (
          <SectionBody
            onChange={setScheduled}
            options={SCHEDULER_OPTIONS}
            value={scheduled}
          />
        )}
      </div>
      <div>
        <SectionHeader
          title={p("regular_categories").toUpperCase()}
          open={openCategories}
          onClick={() => setOpenCategories(!openCategories)}
        />
        {openCategories && (
          <SectionBody
            onChange={setCategory}
            options={categories.map(({ id, name }: any) => ({
              value: id,
              label: name,
            }))}
            value={category}
          />
        )}
      </div>

      <div>
        <SectionHeader
          title={p("gender").toUpperCase()}
          open={openGender}
          onClick={() => setOpenGender(!openGender)}
        />
        {openGender && (
          <div className="home-page-filter-section__gender">
            {[{ value: "all", label: p("all_gender") }, ...GENDER_OPTIONS].map(
              (option) => (
                <div className="home-page-filter-section__gender__item">
                  <RadioButton
                    name={"gender-option"}
                    checked={option.value === gender}
                    onClick={() => setGender(option.value)}
                    value={option.label}
                    label={option.label}
                  />
                </div>
              )
            )}
          </div>
        )}
      </div>

      <div>
        <SectionHeader
          title={p("status").toUpperCase()}
          open={openStatus}
          onClick={() => setOpenStatus(!openStatus)}
        />
        {openStatus && (
          <div className="home-page-filter-section__gender">
            {[{ value: "all", label: p("all_status") }, ...STATUS_OPTIONS].map(
              (option) => (
                <div className="home-page-filter-section__gender__item">
                  <RadioButton
                    name={"gender-option"}
                    checked={option.value === status}
                    onClick={() => setStatus(option.value)}
                    value={option.label}
                    label={option.label}
                  />
                </div>
              )
            )}
          </div>
        )}
      </div>

      <div>
        <SectionHeader
          title={p("dataRange").toUpperCase()}
          open={openDataRange}
          onClick={() => setOpenDataRange(!openDataRange)}
        />
        {openDataRange && (
          <>
            <div className="home-page-filter-section__calendar">
              <Calendar
                value={dataRangeStartDate}
                placeholder={"Start Date"}
                handleDate={setDataRangeStartDate}
              />
            </div>
            <div className="home-page-filter-section__calendar">
              <Calendar
                value={dataRangeEndDate}
                placeholder={"End Date"}
                handleDate={setDataRangeEndDate}
              />
            </div>
          </>
        )}
      </div>
      {/* <div>
        <SectionHeaderCheckbox
          title={p("currently_due_contact").toUpperCase()}
          checked={openCurrently}
          onCheck={() => { }}
          onClick={() => setOpenCurrently(!openCurrently)}
        />
        {openCurrently && (
          <div className="home-page-filter-section__calendar">
            <Calendar
              value={dueVisitStartDate}
              handleDate={setDueVisitStartDate}
            />
          </div>
        )}
      </div>
      <div>
        <SectionHeaderCheckbox
          title={p("deffered_contact").toUpperCase()}
          checked={openDeffered}
          onCheck={() => { }}
          onClick={() => setOpenDeffered(!openDeffered)}
        />
        {openDeffered && (
          <div className="home-page-filter-section__calendar">
            <Calendar
              value={deferredVisitStartDate}
              handleDate={setDeferredVisitStartDate}
            />
          </div>
        )}
      </div> */}
      <div className="home-page-filter-section__footer">
        <Button title={pGeneral("reset")} white onClick={resetFilters} />
        <Button title={pGeneral("apply")} onClick={applyFilters} />
      </div>
    </div>
  );
};
