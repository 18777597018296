import { Getvisits } from "../../../types/api"
import { InferActionsTypes } from "./../index"

export const actionTypes = {
  GET_ALL_VISITS_TRIGGER: "GET_ALL_VISITS_TRIGGER",
  GET_VISITS_TRIGGER: "GET_VISITS_TRIGGER",
  GET_STATUSES_TRIGGER: "GET_STATUSES_TRIGGER",
  GET_REACTIONS_TRIGGER: "GET_REACTIONS_TRIGGER",
  GET_ADDRESS_ERROR_TRIGGER: "GET_ADDRESS_ERROR_TRIGGER",
  GET_NEXT_VISIT_TRIGGER: "GET_NEXT_VISIT_TRIGGER",
  CREATE_NEXT_VISIT_TRIGGER: "CREATE_NEXT_VISIT_TRIGGER",
  GET_STATUSES: "GET_STATUSES",
  GET_REACTIONS: "GET_REACTIONS",
  GET_VISITS: "GET_VISITS",
  GET_ALL_VISITS: "GET_ALL_VISITS",
  GET_SCHEDULED_VISITS: "GET_SCHEDULED_VISITS",
  GET_ADDRESS_ERROR: "GET_ADDRESS_ERROR",
  GET_NEXT_VISIT: "GET_NEXT_VISIT",
  CREATE_NEXT_VISIT: "CREATE_NEXT_VISIT",
  CREATION_NEXT_VISIT: "CREATION_NEXT_VISIT",
  SUCCESS_CREATION_NEXT_VISIT: "SUCCESS_CREATION_NEXT_VISIT",
  ERROR_CREATION_NEXT_VISIT: "ERROR_CREATION_NEXT_VISIT",
  TOGGLE_IS_LOADING: "TOGGLE_IS_LOADING",
  UPDATE_VISIT_TRIGGER: "UPDATE_VISIT_TRIGGER",
  UPDATE_VISIT_SUCCESS: "UPDATE_VISIT_SUCCESS",
  UPDATE_VISIT_ERROR: "UPDATE_VISIT_ERROR",
  DELETE_VISIT_TRIGGER: "DELETE_VISIT_TRIGGER",
  DELETE_VISIT_SUCCESS: "DELETE_VISIT_SUCCESS",
  DELETE_VISIT_ERROR: "DELETE_VISIT_ERROR"
}

type InitialState = {
  visits: null
  scheduledVisits: null
  statuses: null
  reactions: null
  addressError: null
  nextVisit: null
  isLoading: boolean
  allVisits: null
}

const initialState: InitialState = {
  visits: null,
  scheduledVisits: null,
  statuses: null,
  reactions: null,
  addressError: null,
  nextVisit: null,
  isLoading: false,
  allVisits: null
}

export const visitsReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case actionTypes.GET_ALL_VISITS:
      return { ...state, allVisits: action.payload }
    case actionTypes.GET_VISITS:
      return { ...state, visits: action.payload }
    case actionTypes.GET_SCHEDULED_VISITS:
      return { ...state, scheduledVisits: action.payload }
    case actionTypes.GET_STATUSES:
      return { ...state, statuses: action.payload }
    case actionTypes.GET_REACTIONS:
      return { ...state, reactions: action.payload }
    case actionTypes.GET_ADDRESS_ERROR:
      return { ...state, addressError: action.payload }
    case actionTypes.GET_NEXT_VISIT:
      return { ...state, nextVisit: action.payload }
    case actionTypes.TOGGLE_IS_LOADING:
      return { ...state, isLoading: action.payload }

    default:
      return state
  }
}

export const actions = {
  getAllVisitsAC: (data: any) => ({
    type: actionTypes.GET_ALL_VISITS,
    payload: data,
  }),
  getVisitsAC: (data: any) => ({
    type: actionTypes.GET_VISITS,
    payload: data,
  }),
  getScheduledVisitsAC: (data: any) => ({
    type: actionTypes.GET_SCHEDULED_VISITS,
    payload: data,
  }),
  getStatusesAC: (data: any) => ({
    type: actionTypes.GET_STATUSES,
    payload: data,
  }),
  getReactionsAC: (data: any) => ({
    type: actionTypes.GET_REACTIONS,
    payload: data,
  }),
  getAddressErrorAC: (data: any) => ({
    type: actionTypes.GET_ADDRESS_ERROR,
    payload: data,
  }),
  getNextVisitAC: (data: any) => ({
    type: actionTypes.GET_NEXT_VISIT,
    payload: data,
  }),
  toggleIsLoading: (data: any) => ({
    type: actionTypes.TOGGLE_IS_LOADING,
    payload: data,
  }),
}

export const visitsTriggerActions = {
  getAllVisitsTriggerAC: ({ page, perPage, mainPersonFullName, sortOrder, search, sortBy }: Getvisits) => ({
    type: actionTypes.GET_ALL_VISITS_TRIGGER,
    payload: {
      page,
      perPage,
      mainPersonFullName,
      sortBy,
      sortOrder,
      ...search,
    }
  }),
  getVisitsTriggerAC: (id: number, page: number, scheduled: number) => ({
    type: actionTypes.GET_VISITS_TRIGGER,
    id,
    page,
    scheduled,
  }),
  getStatusesTriggerAC: () => ({
    type: actionTypes.GET_STATUSES_TRIGGER,
  }),
  getReactionsTriggerAC: () => ({
    type: actionTypes.GET_REACTIONS_TRIGGER,
  }),
  getAddressErrorTriggerAC: () => ({
    type: actionTypes.GET_ADDRESS_ERROR_TRIGGER,
  }),
  getNextVisitTriggerAC: (id: number) => ({
    type: actionTypes.GET_NEXT_VISIT_TRIGGER,
    id,
  }),
  createNextVisitTriggerAC: (id: number, data: any, userId?: string) => ({
    type: actionTypes.CREATE_NEXT_VISIT_TRIGGER,
    id,
    data,
    userId
  }),
  updateVisitTriggerAC: ({ id, data }: { id: number, data: any }) => ({
    type: actionTypes.UPDATE_VISIT_TRIGGER,
    id,
    data
  }),
  deleteVisitTriggerAC: ({ id }: { id: number }) => ({ 
    type: actionTypes.DELETE_VISIT_TRIGGER,
    id
  }),
}

export type Actions = InferActionsTypes<typeof actions>
