import { FC } from "react"
import "./styles.scss"

interface LoginOptionButtonProps {
  text: string;
  onClick?: () => void;
}

export const LoginOptionButton: FC<LoginOptionButtonProps> = ({ text, onClick }) => {
  return (
    <button className="login-page-chabad-button" type="button" onClick={onClick}>
      <div>{text}</div>
    </button>
  );
};