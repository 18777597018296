import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import loginImage from "../../../assets/images/loginImage.png";
import loginImageMobile from "../../../assets/images/loginImageMobile.png";
import blueChevron from "../../../assets/fonts/blueChevron24.svg";
import { LoginFormik } from "../../../pages/LoginPage/LoginPage";
import { Checkbox } from "../../base/Checkbox";
import { Button } from "../Button";
import { ChabadButton } from "../ChabadButton";
import { LoginOptionButton } from "../LoginOptionButton"
import { Input } from "../Input";
import "./styles.scss";
import { Box } from '@mui/material';
import { useTranslation } from '../../../hooks/useTranslation';
import { authTriggerActions } from "../../../backend/store/reducers/authReducer";
import { useRef } from 'react';
import Cookies from "js-cookie";

interface IProps {
  formik: LoginFormik;
  isDisabled: boolean;
}



export const Layout: FC<IProps> = ({ formik, isDisabled }) => {
  const { p } = useTranslation('first_page');
  const { p: pGeneral } = useTranslation('general');
  const handleEmail = (e: any) => {
    formik.setFieldValue("email", e.target.value);
  };
  const dispatch = useDispatch();
  const handlePassword = (e: any) => {
    formik.setFieldValue("password", e.target.value);
  }
  const handleCheck = (e: any) => {
    let check = +e.target.value ? 0 : 1
    formik.setFieldValue("remember", check);

  }
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [error1, setError] = useState("");
  const [loginByPassword, setLoginByPassword] = useState(false);
  const loginLinkData = useSelector((state: any) => state.authStore.loginLinkData);
  const navigate = useNavigate();
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (loginLinkData) {
      dispatch({ type: 'RESET_LOGIN_LINK_DATA' });
      navigate('/');
    }
  }, [loginLinkData, navigate, dispatch]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const clearCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
      const [name] = cookie.split("=");
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
    });
  };

  const handleSendLoginLink = async () => {
    setDisabled(true)
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      setDisabled(false)
      return;
    }
    setError("");
    dispatch(authTriggerActions.linkLoginUserTriggerAC(email));
    setDisabled(false)
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    const token = urlParams.get("token");

    if (email && token) {
      localStorage.clear();
      sessionStorage.clear();
      clearCookies();

      const checkCsrfToken = async () => {
        let csrfToken = Cookies.get("XSRF-TOKEN");

        if (!csrfToken) {
          await dispatch(authTriggerActions.getTokenTriggerAC());

          await new Promise<void>((resolve, reject) => {
            const interval = setInterval(() => {
              csrfToken = Cookies.get("XSRF-TOKEN");
              if (csrfToken) {
                clearInterval(interval);
                resolve();
              }
            }, 500);

            setTimeout(() => {
              clearInterval(interval);
              reject(new Error("Timeout waiting for CSRF token"));
            }, 10000);
          });
        }

        if (csrfToken) {
          dispatch(authTriggerActions.tokenLoginUserTriggerAC({ email, token }));
        } else {
          console.error("Failed to fetch CSRF token.");
        }
      };

      checkCsrfToken();
    }
  }, [dispatch]);


  return (
    <div className="layout-login-page">
      <Box
        className="layout-login-page__container"
        sx={{
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          className="layout-login-page__form-container"
          sx={{
            width: { xs: "100%", md: "50vw" },
            order: { xs: "2", md: "1" },
            padding: { xs: "25px 0", md: "125px 0" },
          }}
        >
          <Box
            className="layout-login-page__form-container-inner"
            sx={{
              width: { xs: "90%", md: "100%" },
            }}
          >
            <Link to={"/"}>
              <img
                src={blueChevron}
                alt="chevron"
                className="layout-login-page__chevron"
              />
            </Link>
            <form
              className="layout-login-page__form"
              noValidate
              autoComplete="new-password"
            >
              <Box
                className="layout-login-page__form-title"
                sx={{
                  fontSize: { xs: "22px", md: "32px" },
                  textAlign: { xs: "left", md: "center" },
                }}
              >
                {p('lets_sign_in')}
              </Box>

              {!loginByPassword &&
                (
                  <div>
                    <Box
                      style={{
                        letterSpacing: "0.02em",
                        color: "#0e0d5b",
                        fontFamily: "Poppins, sans-serif",
                        marginBottom: "15px"
                      }}
                      sx={{
                        fontSize: { xs: "16px", md: "16px" },
                        textAlign: { xs: "left", md: "center" },
                      }}
                    >
                      Enter your email to receive a login link
                    </Box>
                    <Box className="layout-login-page__form-email" sx={{ marginBottom: { xs: "15px", md: "40px" } }}>
                      <Input
                        label="Email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setEmail(event.target.value);
                          setError("");
                        }}
                        error={!!error1}
                      />
                    </Box>
                    <div className="layout-login-page__button">
                      <Button disabled={!email || disabled} title="Send login link" onClick={handleSendLoginLink} />
                    </div>
                  </div>
                )
              }
              {loginByPassword &&
                (
                  <div>
                    <Box
                      className="layout-login-page__form-email"
                      sx={{
                        marginBottom: { xs: "15px", md: "40px" },
                      }}
                    >
                      <Input
                        label={pGeneral('email')}
                        placeholder={pGeneral('email')}
                        value={formik.values.email}
                        // value={localStorage.getItem('email') ? localStorage.getItem('email') :  formik.values.email }
                        error={formik.touched['email'] && formik?.errors?.email}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          formik.setTouched({ ...formik.touched, email: true })
                          handleEmail(event)
                        }}
                      />
                    </Box>
                    <Box
                      className="layout-login-page__form-password"
                      sx={{
                        marginBottom: { xs: "15px", md: "40px" },
                      }}
                    >
                      <Input
                        label={pGeneral('password')}
                        placeholder={pGeneral('password')}
                        value={formik.values.password}
                        error={formik.touched['password'] && formik?.errors?.password}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          formik.setTouched({ ...formik.touched, password: true })
                          handlePassword(event)
                        }}
                      />
                    </Box>
                    <div className="layout-login-page__checkbox-container">
                      <Checkbox value={String(formik.values.remember)}
                        checked={!!formik.values.remember}
                        label={p('remember_me')}
                        onCheck={(event) => {
                          handleCheck(event);
                        }} />
                      <Link to={"/forgot-password"} className="layout-login-page__forgot">
                        {p('forgot_password_2')}
                      </Link>
                    </div>
                    <div className="layout-login-page__button">
                      <Button
                        disabled={isDisabled}
                        title={p('sign_in')}
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                      />
                    </div>

                  </div>)
              }

              <div className="layout-login-page__line-container">
                <div className="layout-login-page__line" />
              </div>
              <div className="layout-login-page__or-subtitle">{p('or')}</div>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '25px 0',
                }}>
                {!loginByPassword ? <LoginOptionButton text='Login with Password' onClick={() => { setLoginByPassword(true) }} /> :

                  <LoginOptionButton text='One-Time Login Link' onClick={() => { setLoginByPassword(false) }} />
                }
              </Box>
              {/* <Box
                className="layout-login-page__chabad-button"
                sx={{
                  marginBottom: '30px',
                }}>
                <ChabadButton />
              </Box> */}
              <div className="layout-login-page__subtitle">
                {p('or')}{" "}
                <Link
                  to={"/registration"}
                  className="layout-login-page__subtitle-link"
                >
                  {p('create_account')}
                </Link>
              </div>
            </form>
          </Box>
        </Box>
        <Box
          className="layout-login-page__image-container"
          sx={{
            order: { xs: "1", md: "2" },
            height: { xs: "17vh", md: "100vh" },
            backgroundImage: { xs: `url(${loginImageMobile})`, md: `url(${loginImage})` },
            backgroundPosition: { xs: "bottom", md: "top" },
          }}
        />
      </Box>
    </div>
  );
};
