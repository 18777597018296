import { Modal } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import {
  householdsActions,
  householdsTriggerActions,
} from "../../../backend/store/reducers/householdsReducer"
import { Household } from "../../../types/global/types"
import { FilterHeader20 } from "../../base/FilterHeader20"
import { Button } from "../Button"
import { Search } from "../Search"
import "./styles.scss"

type Props = {
  openSearchModal: boolean
  setOpenSearchModal: (value: boolean) => void
  handleAddPoint: (value: Household[]) => void
}

export const SearchModal: FC<Props> = ({
  openSearchModal,
  setOpenSearchModal,
  handleAddPoint,
}) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState("")
  const [filteredHouseholds, setFilteredHouseholds] = useState<Household[]>([])

  useEffect(() => {
    if (value) {
      const sleep = setTimeout(() => {
        dispatch(
          householdsTriggerActions.getHouseholdsTriggerAC({ search: {value:value} })
        )
      }, 500)
      return () => clearTimeout(sleep)
    } else {
      setFilteredHouseholds([])
      dispatch(householdsActions.getHouseholdsAC(null))
    }
  }, [value])

  const storedHouseholds = useSelector(
    (state: any) => state.householdsStore.households?.data
  )

  useEffect(() => {
    if (storedHouseholds) {
      const searchWord = value
      const newFilter = storedHouseholds.filter((elem: Household) =>
        elem?.main_person?.fullName
          .toLowerCase()
          .includes(searchWord.toLowerCase())
      )

      setFilteredHouseholds(newFilter)
    }
  }, [storedHouseholds])

  const handleFilter = (e: any) => {
    setValue(e.target.value)
  }

  const handleConfirm = () => {
    setFilteredHouseholds([])
    setValue("")
    handleAddPoint(filteredHouseholds)
  }

  return (
    <Modal
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      open={openSearchModal}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
    >
      <div className="route-search-modal">
        <FilterHeader20
          title="ADDING NEW POINT TO ROUTE"
          onClose={() => setOpenSearchModal(false)}
        />
        <div className="route-search-modal__container">
          <Search
            value={value}
            filteredHouseholds={
              filteredHouseholds.length === 1 &&
              filteredHouseholds[0].main_person.fullName === value
                ? []
                : filteredHouseholds
              // filteredHouseholds
            }
            onChange={handleFilter}
            setValue={setValue}
          />
        </div>
        <div className="route-search-modal__footer">
          <Button title="Add household address" onClick={handleConfirm} />
        </div>
      </div>
    </Modal>
  )
}
