import { compose } from "../../../../utils/functions";
import { asCreator, withCallbacks } from "../../../../lib/utils";
import { actionTypes } from "../../reducers/visitsReducers";

export const updateVisit = compose(
    withCallbacks,
    asCreator((data: object, id: number) => ({
        type: actionTypes.UPDATE_VISIT_TRIGGER,
        payload: data,
        id: id
    }))
);

export const deleteVisit = compose(
    withCallbacks,
    asCreator((data: object) => ({
        type: actionTypes.DELETE_VISIT_TRIGGER,
        payload: data,
    }))
);