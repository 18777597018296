import { FC, useEffect, useState } from "react"
import { Calendar } from "../../../../ContactsPage/CalendarContacts/Calendar"
import { Checkbox } from "../../../../base/Checkbox"
import { MultiSelect } from "../../../MultiSelect"
import { Select } from "../../../Select"
import { Textarea } from "../../../Textarea"
import { VisitReportCalendar } from "../../../VisitReportCalendar"
import { FormikType } from "../../../../../types/VisitReports/types"
import "./styles.scss"
import { FormErrorMessage } from '../../../../base/FormErrorMessage';
import { ERRORS } from '../../../../../constants/base';
import { useTranslation } from "../../../../../hooks/useTranslation"

const reactionsPalette = [
  {
    scale: 1,
    value: "Happy",
  },
  {
    scale: 2,
    value: "Warm",
  },
  {
    scale: 3,
    value: "Neutral",
  },
  {
    scale: 4,
    value: "Cold",
  },
  {
    scale: 5,
    value: "Not interested",
  },
]

type Props = {
  formik: FormikType
  statuses: string[]
  reactions: {}
  isRemind: boolean
  setRemindedDate: (value: string) => void
  setIsRemind: (value: boolean) => void;
  remindedDate: string

}

export const AddingReport: FC<Props> = ({
  formik,
  statuses,
  reactions,
  isRemind,
  setRemindedDate,
  setIsRemind,
  remindedDate,

}) => {
  const { p } = useTranslation('contact_info');
  const currentReactionValue = reactionsPalette.find(
    (elem) => elem.scale === Number(formik.values.reaction_scale)
  )
  const handleOpen = (value: boolean) => {
    if (!value) {
      formik.setFieldValue("remind_me_at_formatted", null)
    }
    setIsRemind(value)
  }

  const handleStatus = async (value: string) => {
    await formik.setFieldValue("status", value)
    await formik.setTouched({ ...formik.touched, status: true })
  }

  const handleDate = (value: string) => {
    formik.setFieldValue("date_formatted", value)
  }

  const handleReaction = async (value: string) => {
    const result = reactionsPalette.find((elem) => elem.value === value)
    await formik.setFieldValue("reaction_scale", result?.scale);
    await formik.setTouched({ ...formik.touched, reaction_scale: true })
  }

  const handleDetails = (e: any) => {
    formik.setFieldValue("visit_details", e.target.value)
  }
  const handleFollowUpDetails = (e: any) => {
    formik.setFieldValue("follow_up_details", e.target.value)
  }

  return (
    <div className="adding-report-modal-children">
      <div className="adding-report-modal-children__select">
        <Select
          isRequired
          onChangeClose
          label={p('visit_status')}
          list={statuses}
          placeholder={p('select')}
          isError={formik.touched['status'] && !!formik.errors['status']}
          value={formik.values.status}
          handleSelect={handleStatus}
        />
        {formik.touched['status'] && formik.errors['status'] && (
          <FormErrorMessage message={formik.errors.status} />
        )}
      </div>
      <div style={{ display: "none" }} className="adding-report-modal-children__select">
        <MultiSelect
          formik={formik}
          label={p('categories_completed')}
          list={formik.values.checked_categories}
        />
      </div>
      <div className="adding-report-modal-children__calendar">
        <Calendar
          value={formik.values.date_formatted}
          handleDate={handleDate}
        />
      </div>
      <div className="adding-report-modal-children__select adding-report-modal-children__select-reaction">
        <Select
          onChangeClose
          label={p('reaction')}
          list={Object.values(reactions)}
          placeholder={p('select')}
          value={currentReactionValue?.value}
          less
          isError={formik.touched['reaction_scale'] && !!formik.errors['reaction_scale']}
          handleSelect={handleReaction}
        />
        {formik.touched['reaction_scale'] && formik.errors['reaction_scale'] && (
          <FormErrorMessage message={ERRORS.required} />
        )}
      </div>
      <div className="adding-report-modal-children__select">
        <Textarea
          label={p('details_of_visit')}
          placeholder={p('write_your_text')}
          value={formik.values.visit_details}
          onChange={handleDetails}
        />
      </div>
      <div className="adding-report-modal-children__checkbox">
        <Checkbox
          onCheck={() => handleOpen(!isRemind)}
          checked={isRemind}
          label={p('remind_me')}
        />
      </div>
      {isRemind && (
        <>
          <div style={{ margin: "20px 0" }} className="adding-report-modal-children__select">
            <Textarea
              label={p('follow_up_details')}
              placeholder={p('write_your_text')}
              value={formik.values.follow_up_details}
              onChange={handleFollowUpDetails}
            />
          </div>
          <div className="adding-report-modal-children__calendar">
            <VisitReportCalendar
              isRequired={isRemind}
              open={isRemind}
              setRemindedDate={setRemindedDate}
              remindedDate={remindedDate}
            />
          </div>
        </>
      )}
    </div>
  )
}
