import "./styles.scss"
import statusCheck from "../../../assets/fonts/statusCheck.svg"
import happyReaction from "../../../assets/fonts/happyReaction.svg"
import wormReaction from "../../../assets/fonts/wormReaction.svg"
import neturalReaction from "../../../assets/fonts/neturalReaction.svg"
import coldReaction from "../../../assets/fonts/coldReaction.svg"
import rudeReaction from "../../../assets/fonts/rudeReaction.svg"
import remindMe from "../../../assets/fonts/remindMe.svg"
import { FC } from "react"

const reactions = [
  {
    reaction: "Happy",
    icon: happyReaction,
  },
  {
    reaction: "Warm",
    icon: wormReaction,
  },
  {
    reaction: "Neutral",
    icon: neturalReaction,
  },
  {
    reaction: "Cold",
    icon: coldReaction,
  },
  {
    reaction: "Not interested",
    icon: rudeReaction,
  },
]

type Props = {
  remind: boolean
  reactionStatus: string
  remindedDate: string
  status: string
}

export const StatusPanel: FC<Props> = ({
  remind,
  reactionStatus,
  remindedDate,
  status,
}) => {
  const reaction = reactions.find(
    (elem: { reaction: string; icon: string }) =>
      elem.reaction === reactionStatus
  ) || reactions[0];

  return (
    <div className="status-panel">
      <div className="status-panel__cell">
        <div className="status-panel__image">
          <img src={statusCheck} alt="status" />
        </div>
        <div className="status-panel__title-container">
          <div className="status-panel__title">Status</div>
          <div className="status-panel__status">{status}</div>
        </div>
      </div>
      <div className="status-panel__cell">
        <div className="status-panel__image">
          <img src={reaction?.icon} alt="status" />
        </div>
        <div className="status-panel__title-container">
          <div className="status-panel__title">Reaction</div>
          <div className="status-panel__status">{reaction?.reaction}</div>
        </div>
      </div>
      <div
        className={`status-panel__cell ${
          !remind && "status-panel__cell_hidden"
        }`}
      >
        <div className="status-panel__image">
          <img src={remindMe} alt="status" />
        </div>
        <div className="status-panel__title-container">
          <div className="status-panel__title">Remind me</div>
          <div className="status-panel__status">{remindedDate}</div>
        </div>
      </div>
    </div>
  )
}
