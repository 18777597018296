import { Checkbox } from "@mui/material";
import { FC, useEffect, useState } from "react";
import chevronRightFont from "../../../assets/fonts/chevron-right.svg";
import "./RegularCategoriesFilter.scss";
import { useTranslation } from "../../../hooks/useTranslation";
import { householdsTriggerActions } from "../../../backend/store/reducers/householdsReducer";
import { useDispatch } from "react-redux";

type Props = {
  value: string[];
  onChange: (e: any, id: any) => void;

  options: Object[];

  id: any;
};
export const RegularCategoriesFilter: FC<Props> = ({
  options,
  value,
  onChange,
  id,
}) => {
  const { p } = useTranslation("contacts");
  const [popapState, setPopapState] = useState<{ [key: string]: boolean }>({
    isGlobalOpen: false,
  });
  const dispatch = useDispatch();

  const handleOptionClick =
    (changed: string) =>
    ({ target: { checked } }: any) => {
      if (checked) {
        onChange([...value, changed], id);
      } else {
        onChange(
          value.filter((v) => String(v) !== String(changed)),
          id
        );
      }
    };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".regular-categories-filter")) {
        setPopapState({ isGlobalOpen: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="regular-categories-filter" tabIndex={1}>
      <div
        className="regular-categories-filter__background"
        onClick={() => {
          if (options && options?.length <= 0) {
            dispatch(householdsTriggerActions.getHouseholdsCategories());
          }

          setPopapState({
            isGlobalOpen: !popapState.isGlobalOpen,
          });
        }}
      >
        <div className="regular-categories-filter__background-title">
          {p("categories")}
        </div>
        <div className="regular-categories-filter__background-image">
          <img
            src={chevronRightFont}
            alt="chevron"
            style={{
              transform: popapState.isGlobalOpen
                ? "rotate(90deg)"
                : "rotate(270deg)",
            }}
          />
        </div>
      </div>
      <div
        className="regular-categories-filter__content-container"
        style={{
          display: popapState.isGlobalOpen ? "block" : "none",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="regular-categories-filter__content-title">
          {p("regular_categories")}
        </div>
        <div className="regular-categories-filter__filters">
          {options?.map(({ value: curr, label }: any) => {
            return (
              <ul key={curr}>
                <li key={curr}>
                  <div key={curr}>
                    <div className="home-section-body__checkbox" key={curr}>
                      <Checkbox
                        key={curr}
                        checked={value.map(str => str.trim()).includes(curr.toString())}
                        value={curr}
                        onClick={handleOptionClick(String(curr))}
                      />
                      <span>{label}</span>
                    </div>
                  </div>
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    </div>
  );
};
