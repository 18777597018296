import { IUser } from "../../../types/reducers/authReducer";
import { InferActionsTypes } from "./../index";

export const actionTypes = {
  LOGIN_USER_TRIGGER: "LOGIN_USER_TRIGGER",
  REG_USER_TRIGGER: "REG_USER_TRIGGER",
  LOGOUT_TRIGGER: "LOGOUT_TRIGGER",
  GET_TOKEN_TRIGGER: "GET_TOKEN_TRIGGER",
  LOGIN_USER: "LOGIN_USER",
  LOGOUT: "LOGOUT",
  REG_USER: "REG_USER",
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_ERROR: "GET_USER_ERROR",
  SET_USER_TOKEN: "SET_USER_TOKEN",
  GET_TOKEN: "GET_TOKEN",
  GET_TOKEN_FOR_SOCKET_CONNECTION: "GET_TOKEN_FOR_SOCKET_CONNECTION",
  GET_TOKEN_FOR_SOCKET_CONNECTION_SUCCESS: "GET_TOKEN_FOR_SOCKET_CONNECTION_SUCCESS",
  GET_TOKEN_FOR_SOCKET_CONNECTION_ERROR: "GET_TOKEN_FOR_SOCKET_CONNECTION_ERROR",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_ERROR: "FORGET_PASSWORD_ERROR",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  CHANGE_USER_LANGUAGE: "CHANGE_USER_LANGUAGE",
  CHANGE_USER_LANGUAGE_SUCCESS: "CHANGE_USER_LANGUAGE_SUCCESS",
  CHANGE_USER_LANGUAGE_ERROR: "CHANGE_USER_LANGUAGE_ERROR",
  TOKEN_LOGIN_USER_TRIGGER: "TOKEN_LOGIN_USER_TRIGGER",
  LINK_LOGIN_USER_TRIGGER: "LINK_LOGIN_USER_TRIGGER",
  SEND_LOGIN_LINK_SUCCESS: 'SEND_LOGIN_LINK_SUCCESS',
  SEND_LOGIN_LINK_FAILURE: 'SEND_LOGIN_LINK_FAILURE',
  RESET_LOGIN_LINK_DATA:'RESET_LOGIN_LINK_DATA'
};

interface InitialState {
  token: string | null;
  user: IUser | null;
  currentUser: IUser | null;
  loginLinkData  :any | null
}

const initialState: InitialState = {
  token: null,
  user: null,
  currentUser: null,
  loginLinkData: null,
};

export const authReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case actionTypes.SET_USER_TOKEN:
      return { ...state, token: action.payload };
    case actionTypes.LOGIN_USER:
      return { ...state, user: action.payload };
    case actionTypes.GET_USER_SUCCESS:
      return { ...state, currentUser: action.payload };
    case actionTypes.SEND_LOGIN_LINK_SUCCESS:
      return { ...state, loading: false,loginLinkData: action.payload };
      case actionTypes.RESET_LOGIN_LINK_DATA:
      return {
        ...state,
        loginLinkData: null,
      };
    default:
      return state;
  }
};

export const actions = {
  loginUserAC: (data: IUser | null) => ({
    type: actionTypes.LOGIN_USER,
    payload: data,
  }),
  setUser: (data: IUser | null) => ({
    type: actionTypes.GET_USER_SUCCESS,
    payload: data,
  }),
  sendLoginLinkSuccess: (data: any) => ({
    type: actionTypes.SEND_LOGIN_LINK_SUCCESS,
    payload: data,
  })
};

export const authTriggerActions = {
  loginUserTriggerAC: (data: any) => ({
    type: actionTypes.LOGIN_USER_TRIGGER,
    data,
  }),
  regUserTriggerAC: (data: any) => ({
    type: actionTypes.REG_USER_TRIGGER,
    data,
  }),
  logoutUserTriggerAC: () => ({
    type: actionTypes.LOGOUT_TRIGGER,
  }),
  getTokenTriggerAC: () => ({
    type: actionTypes.GET_TOKEN_TRIGGER,
  }),
  tokenLoginUserTriggerAC: (data: { email: string; token: string }) => ({
    type: actionTypes.TOKEN_LOGIN_USER_TRIGGER,
    data,
  }),
  linkLoginUserTriggerAC: (email: string) => ({
    type: actionTypes.LINK_LOGIN_USER_TRIGGER,
    email,
  }),
};

export type Actions = InferActionsTypes<typeof actions>;
