import React, { FC, useEffect, useState } from "react";
import xIcon from "../../../assets/images/xIconLarge.svg";
import { Modal } from "@mui/material";
import "./styled.scss";
import closeIconFont from "../../../assets/fonts/close.svg";
// import { SelectWorkspace } from "../SelectWorkspace/index";
import { SelectWorkspaceModal } from "../SelectWorkspaceModal/index";
import { useDispatch } from "react-redux";
import { API_URL } from "../../../constants/base";
import axios from "axios";
import {getUser} from "../../../backend/store/actions/creators/getUser";
import {householdsTriggerActions} from "../../../backend/store/reducers/householdsReducer";
import {useNavigate} from "react-router-dom";

// interface IErrorModal {
//     isVisible: boolean;
//     setIsVisible: () => void,
//     title: string
// }
interface ISwitchWorkspaceModal {
  setIsVisible?: any;
  setHandleSelect: any;
  //   currentUser: any;
  workspacesUser: any;
  valueSelect: string;
}

export const SwitchWorkspaceModal: FC<ISwitchWorkspaceModal> = ({
  setIsVisible,
  setHandleSelect,
  //   currentUser,
  workspacesUser,
  valueSelect,
}) => {
  const [openModal, setOpenModal] = useState(true);
  const dispatch = useDispatch();
  const [idSelectedWorkspaces, setIdSelectedWorkspaces] = useState(null);
  const navigate = useNavigate();

  const navigateToHouseholds = () => {
    navigate("/saved-routes");
  };
  const reset = () => {
    dispatch(
        householdsTriggerActions.getHouseholdsTriggerAC({
          page: 1,
          perPage: '25',
        })
    );
  };

  useEffect(() => {
    if (idSelectedWorkspaces) {
      // dispatch(switchWorkspace({ workspaceId: 10 }));
      axios
        .post(API_URL + "/api/auth/switch-workspace", {
          workspaceId: idSelectedWorkspaces,
        })
        .then((response) => {
          if (response.status === 200) {

            dispatch(getUser())
            reset()
            navigateToHouseholds()
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [idSelectedWorkspaces]);
  return (
    <Modal
      onClose={setIsVisible}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={openModal}
    >
      <div className="switch-workspace-modal">
        <h3 className="switch-workspace-modal__title">Select Workspace</h3>
        {/* <div className="switch-workspace-modal__wrapper-description"></div> */}
        <SelectWorkspaceModal
          label="Team"
          onChangeClose
          placeholder="Select"
          value={valueSelect}
          handleSelect={setHandleSelect}
          list={workspacesUser} // норм
          //   list={[
          //     //test
          //     { name: "Workspace name1", id: 1 },
          //     { name: "Workspace name2", id: 2 },
          //     { name: "Workspace name3", id: 3 },
          //   ]}
          setOpenModal={setOpenModal}
          //@ts-ignore
          setIdSelectedWorkspaces={setIdSelectedWorkspaces}
          //
          // list={salutationOptions}
          // placeholder="Select"
          // value={formik.values.persons[personIndex].salutation}
          // handleSelect={getSalutation}
        />
      </div>
    </Modal>
  );
};
