import { generalActions } from "./../store/reducers/generalReducer";
import {
  CreateRoute,
  CreateVisitor,
  // GetNotification,
} from "./../../types/api/index";
import axios, { AxiosInstance } from "axios";
import {
  // GetHouseholds,
  GetRoute,
  GetRoutes,
  UpdateRoute,
  GetTeam,
  GetNotifications,
} from "../../types/api";
// import { Household } from "../../types/global/types";
import { LoginUser, RegUser , LoginWithToken} from "../../types/types";
import { store } from "../store";
import { API_URL } from "../../constants/base";

const instance: AxiosInstance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.request.use((request: any) => {
  const token = localStorage.getItem("token") || "";
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      store.dispatch(
        generalActions.errorAC({ message: "Unauthorized", type: "error" })
      );
      window.location.replace("/login");
    }
    if (error.response.status === 500) {
      store.dispatch(
        generalActions.errorAC({ message: "Server error", type: "error" })
      );
    }
    return Promise.reject(error);
  }
);

export const testAPI = {
  testRequest() {
    return instance.get("/api/test");
  },
};

export const authAPI = {
  getToken() {
    return instance.get("/sanctum/csrf-cookie", {
      withCredentials: true,
      headers: {
        Accept: "application/json",
      },
    });
  },

  regUser(payload: RegUser) {
    return instance.post("/api/register", {
      first_name: payload.firstName,
      last_name: payload.lastName,
      email: payload.email,
      password: payload.password,
      shlichus_country: payload.shlichus_country,
      shlichus_country_code: payload.shlichus_country_code,
    });
  },

  loginUser(payload: LoginUser) {
    return instance.post("/api/login", payload);
  },

  logoutUser() {
    return instance.post("/api/logout");
  },

  getUser(token: any) {
    return instance.get("/auth/user", token);
  },

  loginUserWithToken(payload: LoginWithToken) {
    return instance.post("/api/login-with-token", payload);
  },

  sendLoginLink(data: any) {
    return instance.post("/api/send-login-link", data);
  },
};

export const addressAPI = {
  getTypes() {
    return instance.get("api/auth/addresses/get-available-types");
  },
  getAddress() {
    return instance.get("api/auth/addresses");
  },
  refreshPlaceIds() {
    return instance.post("api/auth/addresses/refresh-place-ids");
  },
};

export const categoriesAPI = {
  getCategories() {
    return instance.get("api/auth/categories");
  },
};

// export const householdsAPI = {
  // (payload: object) {
//     return instance.get(
//       `api/auth/households?with[]=categories&with[]=visits&with[]=scheduledVisits&with[]=mainPerson&with[]=address`,
//       {
//         params: payload,
//       }
//     );
//   },
export const householdsAPI = {
  getHouseholds(payload: object) {
    return instance.get(
      `api/auth/households-for-list?with[]=categories&with[]=visits&with[]=scheduledVisits&with[]=mainPerson&with[]=address`,
      {
        params: payload,
      }
    );
  },

  getHouseholdsDownload(payload: object) {
    return instance.get(
      `api/auth/households-for-download?with[]=categories&with[]=visits&with[]=scheduledVisits&with[]=mainPerson&with[]=address`,
      {
        params: payload,
      }
    );
  },

  getHouseholdCategories() {
    return instance.get(`api/auth/categories`);
  },

  createRegularCategory(data: string) {
    return instance.post(`/api/auth/categories`, { name: data });
  },

  deleteRegularCategory(id: number) {
    return instance.delete(`/api/auth/categories/${id}?force=1`);
  },

  updateRegularCategory(id: number, name: string) {
    return instance.put(`/api/auth/categories/${id}`, { name, refresh: 1 });
  },

  deleteHouseholdsByIds(ids: number[]) {
    return instance.post(`/api/auth/households/bulk-actions`, {
      action: "delete",
      ids,
    });
  },

  deleteAllHouseholdsExcludeIds(excludedIds: number[]) {
    return instance.post(`/api/auth/households/bulk-actions`, {
      action: "delete",
      all: true,
      excluded_ids: excludedIds,
    });
  },

  updateRegularCategoriesForHouseholdsByIds(
    ids: number[],
    category_ids: number[]
  ) {
    return instance.post(`/api/auth/households/bulk-actions`, {
      action: "update_regular_categories",
      ids,
      category_ids,
    });
  },

  updateRegularCategoriesForAllHouseholdsExcludeIds(
    excludedIds: number[],
    category_ids: number[]
  ) {
    return instance.post(`/api/auth/households/bulk-actions`, {
      action: "update_regular_categories",
      all: true,
      excluded_ids: excludedIds,
      category_ids,
    });
  },

  updateSchedulingForHouseholdsByIds(ids: number[], periodicity: string) {
    return instance.post(`/api/auth/households/bulk-actions`, {
      action: "update_periodicity",
      ids,
      periodicity,
    });
  },

  updateSchedulingForAllHouseholdsExcludeIds(
    excludedIds: number[],
    periodicity: string
  ) {
    return instance.post(`/api/auth/households/bulk-actions`, {
      action: "update_periodicity",
      all: true,
      excluded_ids: excludedIds,
      periodicity,
    });
  },

  getHouseholdsById(id: number) {
    return instance.get(
      `api/auth/households/${id}?with[]=categories&with[]=persons&with[]=persons.phones&with[]=persons.addresses`
    );
  },

  getPeriodicity() {
    return instance.get("api/auth/households/get-available-periodicity");
  },
};

export const personAPI = {
  getSalutations() {
    return instance.get("api/auth/persons/get-available-salutations");
  },
};

export const visitReportsAPI = {

  getAllVisits(payload: object) {
    return instance.get(
      `api/auth/visits-for-list`,
      {
        params: payload,
      }
    );
  },

  getVisits(id: number, page: number, perPage = 5, scheduled?: number) {
    return instance.get(`api/auth/visits`, {
      params: {
        household_id: id,
        scheduled,
        page,
        perPage,
      },
    });
  },

  getNextHouseholdVisit(id: number) {
    return instance.get(`api/auth/households/${id}/get-next-scheduled-visit`);
  },

  getStatuses() {
    return instance.get("api/auth/visits/get-available-statuses");
  },

  getReactions() {
    return instance.get("api/auth/visits/get-available-reactions");
  },
  getAddressError() {
    return instance.get("api/auth/visits/get-available-address-error-options");
  },

  createNextVisit(id: number, data: any) {
    return instance.put(`api/auth/visits/${id}`, data);
  },
};

export const routesAPI = {
  getRoutes({
    perPage = 10,
    page = 1,
    household,
    address,
    searchValue,
  }: GetRoutes) {
    return instance.get(`api/auth/routes`, {
      params: {
        name: searchValue,
        perPage,
        page,
        with: [
          household && "points.household",
          address && "points.address",
          "startAddress",
        ],
      },
    });
  },

  getRoute({ id, household, address, points }: GetRoute) {
    return instance.get(`api/auth/routes/${id}`, {
      params: {
        with: [
          household && "points.household",
          address && "points.address",
          points && "points",
        ],
      },
    });
  },

  createRoute(data: CreateRoute) {
    return instance.post("api/auth/routes", data);
  },

  updateRoute({ id, data }: UpdateRoute) {
    return instance.put(`api/auth/routes/${id}`, data);
  },

  deleteRoute(id: number) {
    return instance.delete(`api/auth/routes/${id}`);
  },
};

export const teamAPI = {
  getTeam({ perPage = 10, page = 1, searchValue = null }: GetTeam) {
    return instance.get(`api/auth/invitations`, {
      params: {
        perPage,
        page,
        full_name: searchValue,
      },
    });
  },

  createVisitor(data: CreateVisitor) {
    return instance.post("api/auth/invitations", data);
  },

  deleteTeam(id: string) {
    return instance.delete(`api/auth/invitations/${id}`);
  },
};

export const notificationsAPI = {
  getNotifications({ perPage = 10, page = 1, typeSearch }: GetNotifications) {
    return instance.get(`/api/auth/notifications`, {
      params: {
        perPage,
        page,
        type: typeSearch,
      },
    });
  },
  getNotification(id: string) {
    return instance.get(`api/auth/notifications/${id}`);
  },
  getUnreadNotificationsCount() {
    return instance.get("/api/auth/notifications/count-unread-notifications");
  },
  markNotificationAsRead(id: string) {
    return instance.post(`/api/auth/notifications/${id}/mark-as-read`);
  },
};
