import { Outlet, useNavigate } from 'react-router-dom'
import { useEffect, useMemo } from 'react';
import { useSelector } from "react-redux";

export const AuthLayout = () => {
  const navigate = useNavigate()
  const isUser = localStorage.getItem("user");
  const storedUser = useSelector((state: any) => state.authStore.user);
  const user = useMemo(() => storedUser, [storedUser]);
  const workspace = user?.workspaces.find((ws: any) => ws.id === user.currentWorkspace);
  const workspaceRole = workspace ? workspace.workspaceRole : null;

  useEffect(() => {
    if (!isUser) return;
    if (workspaceRole === "ADMIN") {
      navigate("/households");
    }
    else {
      navigate("/saved-routes");
    }
  }, [isUser])
  return (
    <div>
      <Outlet />
    </div>
  )
}
