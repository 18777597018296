import { FormikValues, useFormik } from "formik";
import { FC, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { authTriggerActions } from "../../backend/store/reducers/authReducer";
import { Layout } from "../../components/LoginPage/Layout";
import { emailRegex } from "../../regex";
import { useTranslation } from "../../hooks/useTranslation";
const login = {
  email: "",
  password: "",
  remember: 0,
};

type Formik<T extends FormikValues> = ReturnType<typeof useFormik<T>>;
export type LoginFormik = Formik<typeof login>;
export const LoginPage: FC = () => {
  const { p } = useTranslation("first_page");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUser = localStorage.getItem("user");
  const storedUser = useSelector((state: any) => state.authStore.user);
  const user = useMemo(() => storedUser, [storedUser]);
  const workspace = user?.workspaces.find((ws: any) => ws.id === user.currentWorkspace);
  const workspaceRole = workspace ? workspace.workspaceRole : null;

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(p("email_is_required"))
      .matches(emailRegex, p("invalid_email")),
    password: yup
      .string()
      .required(p("password_is_required"))
      .min(8, p("min_8_characters_length")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: 0,
    },
    validationSchema,
    onSubmit: (data) => {
      dispatch(authTriggerActions.loginUserTriggerAC(data));
    },
  });

  const isDisabled = useMemo(() => {
    return (
      !formik.values.email ||
      !formik.values.password ||
      !!formik.errors.email ||
      !!formik.errors.password
    );
  }, [formik]);

  if (isUser) {
    if (workspaceRole === "ADMIN") {
      navigate("/households");
    }
    else {
      navigate("/saved-routes");
    }
  }

  return <Layout formik={formik} isDisabled={isDisabled} />;
};
