import { put, takeEvery } from "redux-saga/effects"
import { actions, actionTypes } from "../store/reducers/authReducer"
import { RegUser, SagasData } from "../../types/types"
import { updateToken } from "../../utils/updateToken"
import { authAPI, testAPI } from "../api/api"
import { IUser } from "../../types/reducers/authReducer"
import { ILoginUser, IMagicLoginUser } from "../../types/sagas"
import { generalActions } from "../store/reducers/generalReducer"
import { SagaWrapper } from './index';

function* getToken() {
  try {
    const response: { status: number } = yield authAPI.getToken()
    if (response.status === 204 || response.status === 304) {
      updateToken()
    }
  } catch (error) {
    console.log(error)
  }
}

function* regUser(data: SagasData<RegUser>) {
  try {
    const response: { status: number } = yield authAPI.regUser(data.data)
    if (response.status === 200) {
      updateToken()
      yield put(
        generalActions.successAC({ message: "User registred", type: "success" })
      )
      yield put(generalActions.setSuccessActionsAC(true))
    }
  } catch (error: any) {
    yield put(
      generalActions.errorAC({
        message: error.response.data.message,
        type: "error",
      })
    )
  }
}

function* loginUser(payload: ILoginUser) {
  try {
    const response: {
      status: number
      data: {
        user: IUser
      }
    } = yield authAPI.loginUser(payload.data)

    if (response.status === 200) {
      updateToken()
      localStorage.setItem("user", "1")
      yield put(actions.loginUserAC(response.data.user))
    }
  } catch (error: any) {
    yield put(
      generalActions.errorAC({
        message: error.response.data.message,
        type: "error",
      })
    )
  }
}

function* logoutUser() {
  try {
    const response: DataType = yield authAPI.logoutUser()
    if (response.status === 204) {
      localStorage.removeItem("user")
      updateToken()
      yield put(actions.loginUserAC(null))
    }
  } catch (error: any) {
    yield put(
      generalActions.errorAC({
        message: error.response.data.message,
        type: "error",
      })
    )
  }
}

function* testSaga() {
  try {
    const response: DataType = yield testAPI.testRequest()
    console.log(response)
  } catch (error) {
    console.log(error)
  }
}

function* getTokenForSocketConnection({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: 'post',
    endpoint: "/broadcasting/auth",
    body: {
      socket_id: action.id.id,
      channel_name: "private-admin.124"
      // channel_name: action.payload.channelName
    }
  })

  dispatchResponse(
    { data, error },
    actionTypes.GET_TOKEN_FOR_SOCKET_CONNECTION_SUCCESS,
    actionTypes.GET_TOKEN_FOR_SOCKET_CONNECTION_ERROR
  )
}

function* getUser({ request, action, dispatchResponse }: any) {
  const { data, error, status } = yield request({
    method: "get",
    endpoint: "/api/auth/me"
  })

  if (status === 200) {
    localStorage.setItem('language', data.user.language)
    yield put(actions.setUser(data.user))
  }

  // dispatchResponse(
  //     { data, error },
  //     actionTypes.GET_USER_SUCCESS,
  //     actionTypes.GET_USER_ERROR
  // )
}
function* changeUserLanguage({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: 'post',
    endpoint: "/api/auth/set-user-data",
    body: {
      language: action.language.language,
    }
  })

  yield dispatchResponse(
    { data, error },
    actionTypes.CHANGE_USER_LANGUAGE_SUCCESS,
    actionTypes.CHANGE_USER_LANGUAGE_ERROR
  )
}

function* forgetPassword({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "POST",
    endpoint: `/api/forgot-password?email=${action.data.email}`
  })

  yield dispatchResponse(
    { data, error },
    actionTypes.FORGET_PASSWORD_SUCCESS,
    actionTypes.FORGET_PASSWORD_ERROR
  )
}

function* resetPassword({ request, action, dispatchResponse }: any) {
  const { data, error } = yield request({
    method: "POST",
    endpoint: `/api/reset-password?email=${action.data.email}&token=${action.data.token}&password=${action.data.password}&password_confirmation=${action.data.password_confirmation}`,
  });

  yield dispatchResponse(
    { data, error },
    actionTypes.RESET_PASSWORD_SUCCESS,
    actionTypes.RESET_PASSWORD_ERROR
  )
}

function* loginUserWithToken(payload: IMagicLoginUser) {
  try {
    const response: {
      status: number;
      data: { user: IUser; token: string };
    } = yield authAPI.loginUserWithToken(payload.data);

    if (response.status === 200) {
      updateToken();
      localStorage.setItem("user", "1");
      yield put(actions.setUser(response.data.user));
    }
  } catch (error: any) {
    yield put(
      generalActions.errorAC({
        message: error.response?.data?.message || "login failed",
        type: "error",
      })
    );
  }
}

function* sendLoginLink({ email }: any) {
  try {
    const response: {
      status: number;
      data: any;
    } = yield authAPI.sendLoginLink({ email });
    if (response.status === 200) {
      yield put(
        generalActions.successAC({
          message: "A login link has been sent to your email.",
          type: "success",
        })
      );
      yield put(actions.sendLoginLinkSuccess(response.data));
    }
  } catch (error: any) {
    yield put(
      generalActions.errorAC({
        message: error.response?.data?.message || "Failed to send login link.",
        type: "error",
      })
    );
  }
}



export function* authWatcher() {
  yield takeEvery(actionTypes.FORGET_PASSWORD, SagaWrapper.withRequest(forgetPassword))
  yield takeEvery(actionTypes.RESET_PASSWORD, SagaWrapper.withRequest(resetPassword))
  yield takeEvery(actionTypes.LOGIN_USER_TRIGGER, loginUser)
  yield takeEvery(actionTypes.REG_USER_TRIGGER, regUser)
  yield takeEvery(actionTypes.GET_TOKEN_TRIGGER, getToken)
  yield takeEvery(actionTypes.LOGOUT_TRIGGER, logoutUser)
  yield takeEvery(actionTypes.GET_TOKEN_FOR_SOCKET_CONNECTION, SagaWrapper.withRequest(getTokenForSocketConnection))
  yield takeEvery(actionTypes.GET_USER, SagaWrapper.withRequest(getUser))
  yield takeEvery(actionTypes.CHANGE_USER_LANGUAGE, SagaWrapper.withRequest(changeUserLanguage))
  yield takeEvery(actionTypes.TOKEN_LOGIN_USER_TRIGGER, loginUserWithToken)
  yield takeEvery(actionTypes.LINK_LOGIN_USER_TRIGGER, sendLoginLink)
  // yield takeEvery("TEST", testSaga)
}

type DataType = {
  status: number
  data: {
    token: string
  }
}
