import { FC, useEffect, useState } from "react"
import { Calendar } from "../../ContactsPage/CalendarContacts/Calendar"
import { Checkbox } from "../../base/Checkbox"
import { Select } from "../Select"
import { Textarea } from "../Textarea"
import { VisitReportCalendar } from "../VisitReportCalendar"
import { Visit } from "../../../types/VisitReports/types"
import "./styles.scss"
import { useTranslation } from "../../../hooks/useTranslation"
import { Modal } from "@mui/material"
import { FilterHeader } from "../FilterHeader"
import { Button } from "../../ContactsPage/Button";
import dayjs from "dayjs";

const reactionsPalette = [
  { scale: 1, value: "Happy" },
  { scale: 2, value: "Warm" },
  { scale: 3, value: "Neutral" },
  { scale: 4, value: "Cold" },
  { scale: 5, value: "Not interested" }
]

type Props = {
  visit: Visit | null
  statuses: string[]
  reactions: { [key: string]: string }
  setOpen: Function
  onSave: (updatedVisit: any) => void
  onDelete: (id: number) => void
  open: boolean
}

export const EditReport: FC<Props> = ({
  visit,
  statuses,
  reactions,
  onSave,
  onDelete,
  setOpen,
  open
}) => {
  const { p } = useTranslation('contact_info');

  const [status, setStatus] = useState(visit?.status || "")
  const [reactionScale, setReactionScale] = useState(visit?.reaction_scale || 0)
  const [visitDetails, setVisitDetails] = useState(visit?.visit_details || "")
  const [date_formatted, setDate_formatted] = useState(visit?.date_formatted || "")

  const [followUpDetails, setFollowUpDetails] = useState(visit?.follow_up_details || "")
  const [remindMe, setRemindMe] = useState(visit?.remind_me_at_formatted ? true : false)
  const [remindedDateState, setRemindedDateState] = useState("")


  const [reactionName, setReactionName] = useState(visit?.reaction_name || "")

  useEffect(() => {
    if (visit) {
      const selectedReaction = reactionsPalette.find((reaction) => reaction.value === visit.reaction_name)
      setStatus(visit.status)
      setReactionScale(selectedReaction?.scale || 0)
      setVisitDetails(visit.visit_details || "")
      setFollowUpDetails(visit.follow_up_details || "")
      setReactionName(visit.reaction_name || "")
    }
  }, [visit])

  const handleSave = () => {
    const remindedDateStateVal = remindMe ? remindedDateState + " 12:00 AM" : null
    const followUpDetailsVal = remindMe ? followUpDetails : null
    const updatedVisit = {
      ...visit!,
      status: status,
      reaction_scale: reactionScale,
      visit_details: visitDetails,
      follow_up_details: followUpDetailsVal,
      remind_me_at_formatted: remindedDateStateVal,
      reaction_name: reactionName,
      date_formatted: dayjs(date_formatted).format("DD.MM.YYYY"),
    }

    if (!remindMe) {
      updatedVisit.remind_me = false
      updatedVisit.remind_me_at = ""
    }
    onSave(updatedVisit)
  }

  const handleDelete = () => {
    if (visit) onDelete(visit.id)
  }

  const handleStatusChange = (value: string) => {
    setStatus(value)
  }

  const handleReactionChange = (value: string) => {
    const selectedReaction = reactionsPalette.find((reaction) => reaction.value === value)
    setReactionScale(selectedReaction?.scale || 0)
    setReactionName(value)
  }

  const handleDetailsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setVisitDetails(e.target.value)
  }

  const handleFollowUpDetailsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFollowUpDetails(e.target.value)
  }

  const handleReminderChange = (value: boolean) => {
    setRemindMe(value)
    if (!value) {
      setRemindedDateState("")
    }
  }


  return (
    <Modal
      disableAutoFocus
      disableRestoreFocus
      disableEnforceFocus
      open={open}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
    >
      <div className="visit-report-modal">
        <div className="visit-report-modal__header">
          <FilterHeader
            title={p('edit_visit_report')}
            onClose={() => setOpen(false)}
          />
        </div>
        <div className="visit-report-modal__container">
          <div className="adding-report-modal-children"
          // style={{
          //   padding: "40px 35px"
          // }}
          >
            <div className="adding-report-modal-children__select">
              <Select
                isRequired
                onChangeClose
                label={p('visit_status')}
                list={statuses}
                placeholder={p('select')}
                value={status}
                handleSelect={handleStatusChange}
              />
            </div>

            <div className="adding-report-modal-children__calendar">
              <Calendar
                value={date_formatted || ""}
                handleDate={(value) => { setDate_formatted(value) }}
              />
            </div>

            <div className="adding-report-modal-children__select adding-report-modal-children__select-reaction">
              <Select
                onChangeClose
                label={p('reaction')}
                list={Object.values(reactions)}
                placeholder={p('select')}
                value={reactionName}
                less
                handleSelect={handleReactionChange}
              />
            </div>

            <div className="adding-report-modal-children__select">
              <Textarea
                label={p('details_of_visit')}
                placeholder={p('write_your_text')}
                value={visitDetails}
                onChange={handleDetailsChange}
              />
            </div>

            <div className="adding-report-modal-children__checkbox">
              <Checkbox
                onCheck={() => handleReminderChange(!remindMe)}
                checked={remindMe}
                label={p('remind_me')}
              />
            </div>

            {remindMe && (
              <>
                <div style={{ margin: "20px 0" }} className="adding-report-modal-children__select">
                  <Textarea
                    label={p('follow_up_details')}
                    placeholder={p('write_your_text')}
                    value={followUpDetails}
                    onChange={handleFollowUpDetailsChange}
                  />
                </div>
                <div className="adding-report-modal-children__calendar">
                  <VisitReportCalendar
                    isRequired={remindMe}
                    open={remindMe}
                    setRemindedDate={setRemindedDateState}
                    remindedDate={remindedDateState}
                  />
                </div>
              </>
            )}

            <div style={{ margin: "50px auto 10px", width: "150px" }}>
              <Button title="Save" onClick={handleSave} />
            </div>
            <div style={{ margin: "50px auto 10px", width: "150px" }}>
              <Button title="Delete" onClick={handleDelete} />
            </div>

          </div>
        </div>
      </div>
    </Modal>
  )
}