import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { RegularCategoriesFilter } from "../RegularCategoriesFilter/RegularCategoriesFilter";
import { CustomLinkButton } from "../../base/CustomLinkButton/CustomLinkButton";
import { CustomSearch } from "../CustomSearch/CustomSearch";
import { CustomCheckbox } from "../CustomCheckbox/CustomCheckbox";
import { useDispatch } from "react-redux";
import { householdsTriggerActions } from "../../../backend/store/reducers/householdsReducer";
import { Preloader } from "../../base/Preloader/Preloader";
import { PerPageFilter } from "../PerPageFilter";
import deleting from "../../../assets/icons/delete.png";
import "./ContactsList.scss";
import { updateHousehold } from "../../../backend/store/actions/creators/household";
import { GlobalActionsPanel } from "../GlobalActionsPanel";
import { EmptyStateContactsPage } from "../EmptyStateContactsPage";
import emptyStateImg from "../../../assets/images/EmptyStateContactsWIthMagnifyingGlass.svg";
import { ScheduledCategoriesFilter } from "../ScheduledCategoriesFilter";
import { Box } from "@mui/material";
import { SuccessModal } from "../../base/SuccessModal";
import { ErrorModal } from "../../base/ErrorModal";
import { UserContext } from "../../../contexts/UserContext";
import { UserPermissionsEnum } from "../../../enums/userPermissionsEnum";
import { useTranslation } from "../../../hooks/useTranslation";

interface IProps {
  perPageValue: string;
  isLoading: boolean;
  page: number;
  openPerPage: boolean;
  setOpenPerPage: (value: boolean) => void;
  setPerPageValue: (value: string) => void;
  handleDelete: (id: number) => void;
  households: any;
  searchValue: string | null;
  handleSearch: (value: string | null) => void;
  categories: any;
  selectAllHouseholds: (val: any) => void;
  handleChecked: (id: number) => void;
  ids: number[];
  totalSelected: () => number;
  checkedGeneralCheckbox: boolean;
  isVisibleUpdateRegularCategories: boolean;
  isVisibleUpdateScheduledCategories: boolean;
  isVisibleDeleteHouseholds: boolean;
  setIsVisibleUpdateRegularCategories: Dispatch<SetStateAction<boolean>>;
  setIsVisibleUpdateScheduledCategories: Dispatch<SetStateAction<boolean>>;
  setIsVisibleDeleteHouseholds: Dispatch<SetStateAction<boolean>>;
  isExistFilterOrSearchParams: boolean;
  getDataHouseHolds?: () => void;
}

export const ContactsList: FC<IProps> = ({
  page,
  isLoading,
  perPageValue,
  openPerPage,
  setOpenPerPage,
  setPerPageValue,
  handleDelete,
  households,
  handleSearch,
  searchValue,
  categories,
  selectAllHouseholds,
  handleChecked,
  ids,
  totalSelected,
  checkedGeneralCheckbox,
  setIsVisibleUpdateRegularCategories,
  setIsVisibleUpdateScheduledCategories,
  isExistFilterOrSearchParams,
  setIsVisibleDeleteHouseholds,
  getDataHouseHolds
}) => {
  const { p } = useTranslation("contacts");
  const { p: pGeneral } = useTranslation("general");
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();

  const [isSuccessChangedSchedule, setIsSuccessChangedSchedule] =
    useState(false);
  const [isErrorChangedSchedule, setIsErrorChangedSchedule] = useState(false);

  const [isSuccessChangedCategory, setIsSuccessChangedCategory] =
    useState(false);
  const [isErrorChangedCategory, setIsErrorChangedCategory] = useState(false);
  let syncFlight = false;
  const onChangeSchedule = (value: string, id: any) => {
    if (syncFlight) return;
    syncFlight = true;
    dispatch(
      updateHousehold({
        data: { periodicity: value },
        id: id,
        onSuccess: () => {
          syncFlight = false;
          setIsSuccessChangedSchedule(true);
          dispatch(
            householdsTriggerActions.getHouseholdsTriggerAC({
              page: page,
              perPage: perPageValue,
            })
          );
          if (getDataHouseHolds) getDataHouseHolds();
          const timeoutId = setTimeout(() => {
            setIsSuccessChangedSchedule(false);
            clearTimeout(timeoutId);
          }, 2000);
        },
        onError: () => {
          syncFlight = false;
          setIsErrorChangedSchedule(true);
          const timeoutId = setTimeout(() => {
            setIsErrorChangedSchedule(false);
            clearTimeout(timeoutId);
          }, 2000);
        },
      })
    );
  };

  const onChangeCategory = (value: any, id: any) => {
    if (syncFlight) return;
    syncFlight = true;
    dispatch(
      updateHousehold({
        data: { categories: value },
        id: id,
        onSuccess: () => {
          syncFlight = false;
          setIsSuccessChangedCategory(true);
          if (getDataHouseHolds) getDataHouseHolds();
          const timeoutId = setTimeout(() => {
            setIsSuccessChangedCategory(false);
            clearTimeout(timeoutId);
          }, 2000);
        },
        onError: () => {
          syncFlight = false;
          setIsErrorChangedCategory(true);
          const timeoutId = setTimeout(() => {
            setIsErrorChangedCategory(false);
            clearTimeout(timeoutId);
          }, 2000);
        },
      })
    );
  };

  if (!households) {
    return <Preloader />;
  }

  const filterOptions = ["25", "50", "100"];

  return (
    <>
      {(households.data?.length ||
        (!households.data?.length && isExistFilterOrSearchParams) ||
        isLoading) && (
          <>
            <div className="contacts-list">
              <div className="contacts-list__search-panel">
                <Box sx={{ width: { xs: "100%", md: "fit-content" } }}>
                  <CustomSearch
                    text={searchValue || ""}
                    placeholder={p("search_household_name")}
                    handleSearch={handleSearch}
                  />
                </Box>
                <Box
                  sx={{ display: { xs: "none", md: "flex" } }}
                  className="contacts-list__input-container"
                >
                  {pGeneral("showing")}
                  <span>
                    <PerPageFilter
                      value={perPageValue}
                      filterOptions={filterOptions}
                      open={openPerPage}
                      setOpen={setOpenPerPage}
                      setValue={setPerPageValue}
                    />
                  </span>
                  {pGeneral("of")} {households?.meta?.total} {pGeneral("entries")}
                </Box>
              </div>
              {ids.length !== 0 && (
                <GlobalActionsPanel
                  selectedCount={totalSelected()}
                  onDelete={() =>
                    setIsVisibleDeleteHouseholds((prevState) => !prevState)
                  }
                  onUpdateCategories={() =>
                    setIsVisibleUpdateRegularCategories((prevState) => !prevState)
                  }
                  onUpdateScheduling={() =>
                    setIsVisibleUpdateScheduledCategories(
                      (prevState) => !prevState
                    )
                  }
                />
              )}
              <div className="contacts-list__info-panel">
                <div className="contacts-list__cell checkbox">
                  <CustomCheckbox
                    checked={checkedGeneralCheckbox}
                    id={undefined}
                    onChange={selectAllHouseholds}
                  />
                </div>
                <div className="contacts-list__cell name">
                  <h5>{p("household_name")}</h5>
                </div>
                <div className="contacts-list__cell scheduled-categories">
                  <h5>{p("scheduled_categories")}</h5>
                </div>
                <div className="contacts-list__cell regular-categories">
                  <h5>{p("regular_categories")}</h5>
                </div>
                <div className="contacts-list__cell contact">
                  <h5>{p("contact_info")}</h5>
                </div>
                <div className="contacts-list__cell duch">
                  <h5>{p("duch")}</h5>
                </div>
                <div className="contacts-list__cell history">
                  <h5>{p("history")}</h5>
                </div>
                <div className="contacts-list__cell basket"></div>
              </div>
              <div className="contacts-list__list-container">
                {isLoading && (
                  <div className="contacts-list__loader">
                    <Preloader />
                  </div>
                )}
                {!households.data?.length && (
                  <EmptyStateContactsPage
                    img={emptyStateImg}
                    title={p("contact_not_found")}
                    subtitle={p("try_another_name")}
                  />
                )}
                {!!households.data?.length && (
                  <Box sx={{ backgroundColor: "#fff" }}>
                    {households.data.map((element: any) => {
                      return (
                        <div className="contacts-list__list" key={element.id}>
                          <div className="contacts-list__cell">
                            <CustomCheckbox
                              id={element.id}
                              checked={!!ids.find((id) => id === element.id)}
                              onChange={(id: number | undefined) => {
                                if (!id) return;
                                handleChecked(id);
                              }}
                            />
                          </div>
                          <div className="contacts-list__cell name">
                            {element?.householdName}
                          </div>
                          <div className="contacts-list__cell scheduled-categories">
                            <ScheduledCategoriesFilter
                              onChange={(value) => {
                                onChangeSchedule(value, element.id);
                              }}
                              label={element.periodicity}
                              value={element.periodicity}
                            />
                          </div>
                          <div className="contacts-list__cell regular-categories">
                            <RegularCategoriesFilter
                              id={element.id}
                              onChange={onChangeCategory}
                              options={
                                categories?.map(({ id, name }: any) => ({
                                  value: id,
                                  label: name,
                                })) ?? []
                              }
                              value={
                                element?.categories.map(({ id }: any) => id) ?? []
                              }
                            />
                          </div>
                          <div className="contacts-list__cell contact">
                            <CustomLinkButton
                              title={p("contact_info")}
                              link={`/households/update/household-info/${element.id}?tab=info`}
                            />
                          </div>
                          <div className="contacts-list__cell duch">
                            <CustomLinkButton
                              title={p("duch")}
                              isDisabled={!element.scheduledVisitExists}
                              link={`/households/update/history/${element.id}?tab=duch`}
                            />
                          </div>
                          <div className="contacts-list__cell history">
                            <CustomLinkButton
                              title={p("history")}
                              link={`/households/update/history/${element.id}?tab=history`}
                            />
                          </div>
                          <div
                            className={`
                                      contacts-list__cell basket
                                      ${!user?.permissions[
                                UserPermissionsEnum.deleteHouseholds
                              ]
                                ? "isDisabled"
                                : ""
                              }
                                      `}
                          >
                            <img
                              onClick={() => handleDelete(element.id)}
                              className="contacts-list__cell-image"
                              src={deleting}
                              alt="delete"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Box>
                )}
              </div>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  padding: "15px 0px",
                  display: { xs: "block", md: "none" },
                  borderTop: "1px solid #C5CAE3",
                }}
              >
                <Box
                  sx={{
                    display: { xs: "flex", md: "none" },
                    justifyContent: "center",
                  }}
                  className="contacts-list__input-container"
                >
                  {pGeneral("showing")}
                  <span>
                    <PerPageFilter
                      value={perPageValue}
                      open={openPerPage}
                      setOpen={setOpenPerPage}
                      setValue={setPerPageValue}
                      menuPlacement={"top"}
                    />
                  </span>
                  {pGeneral("of")} {households?.meta?.total} {pGeneral("entries")}
                </Box>
              </Box>
            </div>
          </>
        )}
      <SuccessModal
        isVisible={isSuccessChangedSchedule}
        setIsVisible={() =>
          setIsSuccessChangedSchedule((prevState) => !prevState)
        }
        title={"The scheduled category updated"}
      />
      <ErrorModal
        isVisible={isErrorChangedSchedule}
        setIsVisible={() =>
          setIsErrorChangedSchedule((prevState) => !prevState)
        }
        title={"Something went wrong"}
      />
      <SuccessModal
        isVisible={isSuccessChangedCategory}
        setIsVisible={() =>
          setIsSuccessChangedCategory((prevState) => !prevState)
        }
        title={"The regular categories updated"}
      />
      <ErrorModal
        isVisible={isErrorChangedCategory}
        setIsVisible={() =>
          setIsSuccessChangedCategory((prevState) => !prevState)
        }
        title={"Something went wrong"}
      />
    </>
  );
};
